import firebase from "firebase"

import type { Game, GameRunStatus } from "@/types/game"

import { FirebaseService } from "./firebase.service"

export default class GameService extends FirebaseService {
  // TODO: get rid of this NASTY method when Firebase is ready
  // Don't try to understand it, just contant Andrew
  getGames(
    orgID: string,
    {
      startAt,
      endAt,
      page,
      pageSize
    }: {
      startAt?: string
      endAt?: string
      page: number
      pageSize: number
    }
  ) {
    const ref = firebase.database().ref(`org/${orgID}/games`).orderByKey()

    let query = ref

    if (startAt || endAt) {
      if (startAt) {
        query = query.startAt(startAt)
      } else if (endAt) {
        query = query.endAt(endAt)
      }
    }

    if (startAt) {
      query = query.limitToFirst(pageSize + 2)
    } else {
      query = query.limitToLast(endAt ? pageSize + 2 : pageSize + 1)
    }

    return query.once("value").then(snapshot => {
      const value = snapshot.val()

      if (value && (startAt || endAt)) {
        delete value[endAt]
        delete value[startAt]
      }

      if (value) {
        const array = this.normalizeSnapshotToArray(
          value as Record<string, unknown>
        )

        const isLast = array.length < pageSize

        if (!isLast && array.length > pageSize) {
          if (startAt) {
            array.shift()
          }
          if (endAt) {
            array.pop()
          }
        }

        if (array.length === 0) {
          return {
            total: pageSize * (page - 1),
            value: null
          }
        }

        return {
          total: isLast ? pageSize * (page - 1) + array.length : undefined,
          value: array.reverse()
        }
      } else {
        return {
          total: pageSize * (page - 1),
          value
        }
      }
    })
  }

  getGamesByName(orgID: string, name: string) {
    return firebase
      .database()
      .ref(`org/${orgID}/games`)
      .orderByChild("name")
      .startAt(name)
      .endAt(name + "\u{F8FF}")
      .once("value")
      .then(snapshot => {
        const value = snapshot.val()
        return value ? this.normalizeSnapshotToArray(value) : []
      })
  }

  getGamesByRunStatus(orgID: string, runStatus: GameRunStatus) {
    return firebase
      .database()
      .ref(`org/${orgID}/games`)
      .orderByChild("runStatus")
      .equalTo(runStatus)
      .once("value")
      .then(snapshot => {
        const value: Record<string, Game> | null = snapshot.val()
        return value ? this.normalizeSnapshotToArray(value) : []
      })
  }

  getGamesByHost(orgID: string, hostID: string) {
    return firebase
      .database()
      .ref(`org/${orgID}/games`)
      .orderByChild("hostUserID")
      .equalTo(hostID)
      .once("value")
      .then(snapshot => {
        const value: Record<string, Game> | null = snapshot.val()
        return value ? this.normalizeSnapshotToArray(value) : []
      })
  }

  getGameByID(orgID: string, gameID: string) {
    return firebase
      .database()
      .ref(`org/${orgID}/games/${gameID}`)
      .once("value")
      .then(snapshot => {
        const value: Game | null = snapshot.val()
        return value ? this.normalizeObjectLikeSnapshot(value, gameID) : value
      })
  }

  getGamesByClientID(orgID: string, clientID: string) {
    return firebase
      .database()
      .ref(`org/${orgID}/games`)
      .orderByChild("clientID")
      .equalTo(clientID)
      .once("value")
      .then(snapshot => {
        const value: Record<string, Game> | null = snapshot.val()
        return value ? this.normalizeSnapshotToArray(value) : []
      })
  }

  getCompletedGames(orgID: string) {
    return firebase
      .database()
      .ref(`org/${orgID}/games`)
      .orderByChild("endTimestamp")
      .startAt(1)
      .once("value")
      .then(snapshot => {
        const value = snapshot.val()
        return this.normalizeSnapshotToArray(value)
      })
  }

  getFutureGames(orgID: string) {
    return firebase
      .database()
      .ref(`org/${orgID}/games`)
      .orderByChild("startTimestamp")
      .startAt(Date.now())
      .once("value")
      .then(snapshot => {
        const value = snapshot.val()
        return this.normalizeSnapshotToArray(value)
      })
  }
}
