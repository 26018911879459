export abstract class FirebaseService {
  protected normalizeSnapshotToArray<T extends any>(
    snapshot: Record<string, T>
  ) {
    return Object.keys(snapshot).map(key => {
      return this.normalizeObjectLikeSnapshot(snapshot[key], key)
    })
  }

  protected normalizeObjectLikeSnapshot<T extends Record<string, any>>(
    snapshot: T,
    identity: string
  ) {
    return { ...snapshot, id: identity, theKey: identity }
  }
}
